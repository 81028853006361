import React, {FC, useContext, useEffect, useState} from 'react';
import {Context} from "./index";
import {observer} from "mobx-react-lite";
import {BrowserRouter} from 'react-router-dom'
import {useRoutes} from './routes'
import { Navbar } from './components/Navbar'
import 'materialize-css'



const App: FC = () => {
  const {store} = useContext(Context);


  useEffect(() => {
    if (localStorage.getItem('token')) {
      store.checkAuth()
    }
  }, [])

  const routes = useRoutes(store.isAuth)

  return (
      <BrowserRouter>
        { store.isAuth && <Navbar /> }
        <div className="main">
          { routes }
        </div>
      </BrowserRouter>
  );
}

export default observer(App);
