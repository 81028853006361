import React, { useEffect, useState } from 'react'
import {useMessage} from '../hooks/message.hook'
import { useParams } from 'react-router-dom'
import M from "materialize-css";
import {VisitorsChart} from "../components/charts/VisitorsChart";
import '../css/SiteDetailsPage.css'

let defaultVisitors = [];
let today = new Date();

for(let i = 0; i <= 7; i++) {
    let secondDay = 3600*1000*24;
    let date = new Date(today.getTime() + secondDay*i)
    if(i === 0) {
        date = new Date(today.getTime())
    }
    let day = date.getUTCDate();
    let month = date.getUTCMonth() + 1;
    let year = date.getUTCDate();
    defaultVisitors.push({
        date: String(day + '-' + month + '-' + year),
        Visits: 0,
        StubHubClicks: 0
    })
}

let months = '';
let d = new Date();
d.setDate(1);
for (let i=0; i<=1; i++) {
    let zero = '0'
    if(String((d.getMonth() + 1)).length > 1) {
        zero = ''
    }
        months += zero + ((d.getMonth() + 1)  + '-' + d.getFullYear()) + '|';
}
months = months.substr(0,months.length - 1)

export const MiniTable = (status) => {
    let st = status.value;
        return (
            <ul>
                <li>Tickets: {st.tickets}</li>
                <li>Parking: {st.parking}</li>
                <li>VIP: {st.vip}</li>
                <li>Расписание обновлено: { new Date(Date.parse(st.last_update)).toLocaleString() }</li>
                <li>Посещений сегодня: {st.todayUsers}</li>
                <li>Кликов на StubHub: {st.todayClicks}</li>
                <li>Сейчас на сайте: {st.online}</li>
            </ul>
        )
}

export const SiteDetailsPage = () => {
  const params = useParams()
  const message = useMessage()
    const [status, setStatus] = useState({
        tickets: 'Загрузка..',
        parking: 'Загрузка..',
        vip: 'Загрузка..',
        last_update: 'Загрузка..',
        todayUsers: 'Загрузка..',
        todayClicks: 'Загрузка..',
        online: 'Загрузка..'
    })
  const [sitename, setSitename] = useState('Загрузка...')
    const [image, setImage] = useState('')
    const [visitors, setVisitors] = useState(defaultVisitors)
    const [dateQuery, setDate] = useState(months)
    const [activeRange, setActiveRange] = useState(1)


  useEffect(() => {

      function getScreen(id) {
          let headers = {};
          headers['Content-Type'] = "application/json"
          fetch("/get/site/screenshot", {
              headers: headers,
              method: 'POST',
              body: JSON.stringify({id: id})
          })
              .then(res => res.json())
              .then(
                  (result) => {
                      setImage('')
                      setImage({
                          image: `data:image/png;base64,${new Buffer.from(result.screenshot).toString("ascii")}`,
                          caption: result.screenshot_date
                      })
                      M.AutoInit(undefined);
                  },
                  (error) => {
                      console.log(error)
                      setImage('')
                  }
              )
      }
      function updateOnline(site, status) {
          let headers = {};
          headers['Content-Type'] = "application/json"
          fetch("/get/site/online", { headers: headers, method: 'POST', body: JSON.stringify({url: site.site_url, app: site.app})})
              .then(res => res.json())
              .then(
                  (result) => {
                      let newStatus = status
                      newStatus.online = result.count
                      setStatus(newStatus)
                  },
                  (error) => console.log(error)
              )
      }
      async function getStatistics(site, status) {

          let headers = {};
          headers['Content-Type'] = "application/json"
          let toChart = [];
          await fetch("/get/site/visitors", {
              headers: headers,
              method: 'POST',
              body: JSON.stringify({url: site.site_url, app: site.app, date: dateQuery})
          })
              .then(res => res.json())
              .then(
                  async (result) => {

                      let composed = new Map();

                      result.forEach((user) => {
                          if(composed.has(user.visit_date)) {
                              let u = composed.get(user.visit_date)
                              u.push(user);
                              composed.set(user.visit_date, u)
                          } else {
                              let u = [];
                              u.push(user);
                              composed.set(user.visit_date, u)
                          }
                      })
                      let minidash = JSON.parse(status);
                      minidash.todayClicks = 0;
                      minidash.todayUsers = 0;
                      minidash.online = 0;
                      let date = new Date(today.getTime())
                      let day = date.getDate();
                      let month = date.getMonth() + 1;
                      let year = date.getFullYear();
                      let datestr = day + '-' + month + '-' + year;


                      for (let key of composed.keys()) {
                          let date = {}
                          let dateObj = composed.get(key)
                          date.date = key
                          date.Visits = 0
                          date.StubHubClicks = 0
                          date.Google = 0
                          date.Bing = 0
                          date.Yahoo = 0
                          date.Duckduck = 0
                          date.allDay = dateObj
                          dateObj.forEach(e => {
                              date.Visits = date.Visits + 1
                              if(datestr === key) {
                                  minidash.todayUsers = minidash.todayUsers + 1;
                              }
                              if(e.away_urls !== '') {
                                  date.StubHubClicks = date.StubHubClicks + 1
                                  if(datestr === key) {
                                      minidash.todayClicks = minidash.todayClicks + 1;
                                  }
                              }
                              if(e.visit_urls.indexOf('google') > -1) {
                                  date.Google = date.Google + 1
                              }
                              if(e.visit_urls.indexOf('bing') > -1) {
                                  date.Bing = date.Bing + 1
                              }
                              if(e.visit_urls.indexOf('duckduck') > -1) {
                                  date.Duckduck = date.Duckduck + 1
                              }
                              if(e.visit_urls.indexOf('yahoo') > -1) {
                                  date.Yahoo = date.Yahoo + 1
                              }
                          })
                           toChart.push(date)
                      }
                      await updateOnline(site ,minidash)
                  },
                  (error) => {
                      console.log(error)
                  }
              )
          setVisitors(toChart)
      }

          let headers = {};
          headers['Content-Type'] = "application/json"
          fetch("/get/site/id", {headers: headers, method: 'POST', body: JSON.stringify({id: params.id})})
              .then(res => res.json())
              .then(
                  async (result) => {
                      setSitename(result.data.name)
                      await getScreen(params.id)
                      await getStatistics(result.data, result.status)
                  },
                  (error) => {
                      message('Ошибка: Неверный URL или база данных недоступна.')
                  }
              )



  }, [dateQuery])

  return(
    <div className="workspace">
      <div className="tab-title">{ sitename } - Аналитика</div>
        <div className="top-block">
            
            <div className="mini-table">
                <MiniTable value={ status } />
            </div>

        </div>
        <VisitorsChart visitors={ visitors } refresh={ setDate } active={ activeRange } setActive={ setActiveRange }/>
    </div>
  )
}
