import React, { useEffect, useState } from 'react'


function sortStat(stat, by) {
    let statistics = JSON.parse(JSON.stringify(stat))
    if(by !== 'default') {

        statistics.sort((x, y) => y[by] - x[by]);

    }
    return statistics
}


export const StatsTable = (props) => {

    const [visitors, setVisitors] = useState(props.visitors);
    const [sort, setSort] = useState('default');
    
    let loading = props.loading

    useEffect(() => {

        setVisitors(sortStat(visitors, sort))


    }, [sort])



    if(loading) {
        return(
            <table className='sites-table-wrapper'>
                <thead className='sites-table-header'>
                <tr>
                    <td className='sites-table-col tra'>Date</td>
                    <td className='sites-table-col trb'>Site</td>
                    <td className='sites-table-col trbb'>Conversion</td>
                    <td className='sites-table-col traa'>Visits</td>
                    <td className='sites-table-col trd'>Dates</td>
                    <td className='sites-table-col tre'>Checkouts</td>
                    <td className='sites-table-col trf'>Visits Mobile</td>
                    <td className='sites-table-col trg'>Dates Mobile</td>
                    <td className='sites-table-col trh'>Checkouts Mobile</td>
                    <td className='sites-table-col tri'>Visits Tablet</td>
                    <td className='sites-table-col trj'>Dates Tablet</td>
                    <td className='sites-table-col trk'>Checkouts Tablet</td>
                    <td className='sites-table-col trl'>Google</td>
                    <td className='sites-table-col trm'>Bing</td>
                    <td className='sites-table-col trn'>Yahoo</td>
                    <td className='sites-table-col tro'>DuckDuck</td>
                    </tr>
                </thead>
                <tbody className='sites-table-body'>
                    
                </tbody>
            </table>
        )
    } else {
        return(
            <table className='sites-table-wrapper'>
                <thead className='sites-table-header'>
                    <tr>
                    <td className='sites-table-col tra'>Date</td>
                    <td className='sites-table-col trb'>Site</td>
                    <td className='sites-table-col trbb'
                    onClick={(e) => {
                        setSort('conversion')
                        document.querySelectorAll('.sorted').forEach(el => {
                            el.classList.remove('sorted')
                        })
                        e.target.classList.add('sorted')
                    }}
                    >Conversion</td>
                    <td className='sites-table-col traa'
                    onClick={(e) => {
                        setSort('visits')
                        document.querySelectorAll('.sorted').forEach(el => {
                            el.classList.remove('sorted')
                        })
                        e.target.classList.add('sorted')
                    }}
                    >Visits</td>
                    <td className='sites-table-col trd'
                    onClick={(e) => {
                        setSort('dates')
                        document.querySelectorAll('.sorted').forEach(el => {
                            el.classList.remove('sorted')
                        })
                        e.target.classList.add('sorted')
                    }}
                    >Dates</td>
                    <td className='sites-table-col tre'
                    onClick={(e) => {
                        setSort('checkouts')
                        document.querySelectorAll('.sorted').forEach(el => {
                            el.classList.remove('sorted')
                        })
                        e.target.classList.add('sorted')
                    }}
                    >Checkouts</td>
                    <td className='sites-table-col trf'
                    onClick={(e) => {
                        setSort('visits_m')
                        document.querySelectorAll('.sorted').forEach(el => {
                            el.classList.remove('sorted')
                        })
                        e.target.classList.add('sorted')
                    }}
                    >Visits Mobile</td>
                    <td className='sites-table-col trg'
                    onClick={(e) => {
                        setSort('dates_m')
                        document.querySelectorAll('.sorted').forEach(el => {
                            el.classList.remove('sorted')
                        })
                        e.target.classList.add('sorted')
                    }}
                    >Dates Mobile</td>
                    <td className='sites-table-col trh'
                    onClick={(e) => {
                        setSort('checkouts_m')
                        document.querySelectorAll('.sorted').forEach(el => {
                            el.classList.remove('sorted')
                        })
                        e.target.classList.add('sorted')
                    }}
                    >Checkouts Mobile</td>
                    <td className='sites-table-col tri'
                    onClick={(e) => {
                        setSort('visits_t')
                        document.querySelectorAll('.sorted').forEach(el => {
                            el.classList.remove('sorted')
                        })
                        e.target.classList.add('sorted')
                    }}
                    >Visits Tablet</td>
                    <td className='sites-table-col trj'
                    onClick={(e) => {
                        setSort('dates_t')
                        document.querySelectorAll('.sorted').forEach(el => {
                            el.classList.remove('sorted')
                        })
                        e.target.classList.add('sorted')
                    }}
                    >Dates Tablet</td>
                    <td className='sites-table-col trk'
                    onClick={(e) => {
                        setSort('checkouts_t')
                        document.querySelectorAll('.sorted').forEach(el => {
                            el.classList.remove('sorted')
                        })
                        e.target.classList.add('sorted')
                    }}
                    >Checkouts Tablet</td>
                    <td className='sites-table-col trl'
                    onClick={(e) => {
                        setSort('google')
                        document.querySelectorAll('.sorted').forEach(el => {
                            el.classList.remove('sorted')
                        })
                        e.target.classList.add('sorted')
                    }}
                    >Google</td>
                    <td className='sites-table-col trm'
                    onClick={(e) => {
                        setSort('bing')
                        document.querySelectorAll('.sorted').forEach(el => {
                            el.classList.remove('sorted')
                        })
                        e.target.classList.add('sorted')
                    }}
                    >Bing</td>
                    <td className='sites-table-col trn'
                    onClick={(e) => {
                        setSort('yahoo')
                        document.querySelectorAll('.sorted').forEach(el => {
                            el.classList.remove('sorted')
                        })
                        e.target.classList.add('sorted')
                    }}
                    >Yahoo</td>
                    <td className='sites-table-col tro'
                    onClick={(e) => {
                        setSort('duckduck')
                        document.querySelectorAll('.sorted').forEach(el => {
                            el.classList.remove('sorted')
                        })
                        e.target.classList.add('sorted')
                    }}
                    >DuckDuck</td>
                    </tr>
                </thead>
                <tbody className='sites-table-body'>
                    { visitors.map((row) => {
                        return(
                            <tr className='sites-table-row'>
                                <td className='sites-table-col tra' 
                                onMouseOver={(e) => {
                                            e.target.style.background = '#fb2e2e26'
                                            document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                                c.style.background = '#fb2e2e26'
                                            })
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.background = 'transparent'
                                    document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                        c.style.background = 'transparent'
                                    })
                                }}
                                >{row.date}</td>
                                <td className='sites-table-col trb' 
                                onMouseOver={(e) => {
                                            e.target.style.background = '#fb2e2e26'
                                            document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                                c.style.background = '#fb2e2e26'
                                            })
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.background = 'transparent'
                                    document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                        c.style.background = 'transparent'
                                    })
                                }}>{row.site}</td>
                                                                <td className='sites-table-col trb' 
                                onMouseOver={(e) => {
                                            e.target.style.background = '#fb2e2e26'
                                            document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                                c.style.background = '#fb2e2e26'
                                            })
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.background = 'transparent'
                                    document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                        c.style.background = 'transparent'
                                    })
                                }}>{row.conversion}%</td>
                                <td className='sites-table-col traa' 
                                onMouseOver={(e) => {
                                            e.target.style.background = '#fb2e2e26'
                                            document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                                c.style.background = '#fb2e2e26'
                                            })
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.background = 'transparent'
                                    document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                        c.style.background = 'transparent'
                                    })
                                }}
                                >{row.visits}</td>
                                <td className='sites-table-col trd' 
                                onMouseOver={(e) => {
                                            e.target.style.background = '#fb2e2e26'
                                            document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                                c.style.background = '#fb2e2e26'
                                            })
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.background = 'transparent'
                                    document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                        c.style.background = 'transparent'
                                    })
                                }}>{row.dates}</td>
                                <td className='sites-table-col tre' 
                                onMouseOver={(e) => {
                                            e.target.style.background = '#fb2e2e26'
                                            document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                                c.style.background = '#fb2e2e26'
                                            })
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.background = 'transparent'
                                    document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                        c.style.background = 'transparent'
                                    })
                                }}>{row.checkouts}</td>
                                <td className='sites-table-col trf' 
                                onMouseOver={(e) => {
                                            e.target.style.background = '#fb2e2e26'
                                            document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                                c.style.background = '#fb2e2e26'
                                            })
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.background = 'transparent'
                                    document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                        c.style.background = 'transparent'
                                    })
                                }}>{row.visits_m}</td>
                                <td className='sites-table-col trg' 
                                onMouseOver={(e) => {
                                            e.target.style.background = '#fb2e2e26'
                                            document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                                c.style.background = '#fb2e2e26'
                                            })
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.background = 'transparent'
                                    document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                        c.style.background = 'transparent'
                                    })
                                }}>{row.dates_m}</td>
                                <td className='sites-table-col trh' 
                                onMouseOver={(e) => {
                                            e.target.style.background = '#fb2e2e26'
                                            document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                                c.style.background = '#fb2e2e26'
                                            })
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.background = 'transparent'
                                    document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                        c.style.background = 'transparent'
                                    })
                                }}>{row.checkouts_m}</td>
                                <td className='sites-table-col tri' 
                                onMouseOver={(e) => {
                                            e.target.style.background = '#fb2e2e26'
                                            document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                                c.style.background = '#fb2e2e26'
                                            })
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.background = 'transparent'
                                    document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                        c.style.background = 'transparent'
                                    })
                                }}>{row.visits_t}</td>
                                <td className='sites-table-col trj' 
                                onMouseOver={(e) => {
                                            e.target.style.background = '#fb2e2e26'
                                            document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                                c.style.background = '#fb2e2e26'
                                            })
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.background = 'transparent'
                                    document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                        c.style.background = 'transparent'
                                    })
                                }}>{row.dates_t}</td>
                                <td className='sites-table-col trk' 
                                onMouseOver={(e) => {
                                            e.target.style.background = '#fb2e2e26'
                                            document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                                c.style.background = '#fb2e2e26'
                                            })
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.background = 'transparent'
                                    document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                        c.style.background = 'transparent'
                                    })
                                }}>{row.checkouts_t}</td>
                                <td className='sites-table-col trl' 
                                onMouseOver={(e) => {
                                            e.target.style.background = '#fb2e2e26'
                                            document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                                c.style.background = '#fb2e2e26'
                                            })
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.background = 'transparent'
                                    document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                        c.style.background = 'transparent'
                                    })
                                }}>{row.google}</td>
                                <td className='sites-table-col trm' 
                                onMouseOver={(e) => {
                                            e.target.style.background = '#fb2e2e26'
                                            document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                                c.style.background = '#fb2e2e26'
                                            })
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.background = 'transparent'
                                    document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                        c.style.background = 'transparent'
                                    })
                                }}>{row.bing}</td>
                                <td className='sites-table-col trn' 
                                onMouseOver={(e) => {
                                            e.target.style.background = '#fb2e2e26'
                                            document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                                c.style.background = '#fb2e2e26'
                                            })
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.background = 'transparent'
                                    document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                        c.style.background = 'transparent'
                                    })
                                }}>{row.yahoo}</td>
                                <td className='sites-table-col tro' 
                                onMouseOver={(e) => {
                                            e.target.style.background = '#fb2e2e26'
                                            document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                                c.style.background = '#fb2e2e26'
                                            })
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.background = 'transparent'
                                    document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                        c.style.background = 'transparent'
                                    })
                                }}>{row.duckduck}</td>
                            </tr>
                        )
                    }) }
                </tbody>
            </table>
        )
    }
    
}