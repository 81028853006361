import React, { useEffect, useState } from 'react'


function getCheckouts(callback) {

    let headers = {};
    headers['Content-Type'] = "application/json"
    let toChart = [];
    fetch("/get/analytics/get_checkouts", {
        headers: headers,
        method: 'POST'
    }).then(r => r.json())
    .then(r => {
        if(r) {
            callback(r)
          } else {
            callback([])
          }
    })
    
  }

export const Checkouts = (props) => {
    const [loading, setLoading] = useState(true)
    const [sites, setSites] = useState([])
   
    let period = props.period
    let year = props.year

    useEffect(async () => {
        setLoading(true)
        getCheckouts((r) => {
            setSites(r)
            setLoading(false)
        })
      }, [period, year])

    if(loading) {
        return(
            <table className='sites-table-wrapper'>
                <thead className='sites-table-header'>
                <tr>
                    <td className='sites-table-col tra'>From site</td>
                    <td className='sites-table-col trb'>Order Date</td>
                    <td className='sites-table-col traa'>Method</td>
                    <td className='sites-table-col trd' style={{width:"250px"}}>Event</td>
                    <td className='sites-table-col tre'>Event Date</td>
                    <td className='sites-table-col trf' style={{width:"250px"}}>Venue</td>
                    <td className='sites-table-col trg' style={{width:"200px"}}>Section</td>
                    <td className='sites-table-col trh'>Row</td>
                    <td className='sites-table-col tri' style={{width:"50px"}}>Qty</td>
                    <td className='sites-table-col trj' style={{width:"120px"}}>Retail Price</td>
                    <td className='sites-table-col trk' style={{width:"120px"}}>Fee</td>
                    <td className='sites-table-col trl'>Delivery</td>
                    <td className='sites-table-col trm' style={{width:"80px"}}>Taxes</td>
                    <td className='sites-table-col trn' style={{width:"120px"}}>Total Price</td>
                    <td className='sites-table-col tro' style={{width:"100px"}}>Entry Time</td>
                    <td className='sites-table-col trp'>Exit Time</td>
                    <td className='sites-table-col trq' style={{width:"100px"}}>Order Placed</td>
                    <td className='sites-table-col trr'>Attempts</td>
                    <td className='sites-table-col trc'>Email</td>
                    </tr>
                </thead>
                <tbody className='sites-table-body'>
                    
                </tbody>
            </table>
        )
    } else {
        return(
            <table className='sites-table-wrapper'>
                <thead className='sites-table-header'>
                    <tr>
                    <td className='sites-table-col tra'>From site</td>
                    <td className='sites-table-col trb'>Order Date</td>
                    <td className='sites-table-col traa'>Method</td>
                    <td className='sites-table-col trd' style={{width:"250px"}}>Event</td>
                    <td className='sites-table-col tre'>Event Date</td>
                    <td className='sites-table-col trf' style={{width:"250px"}}>Venue</td>
                    <td className='sites-table-col trg' style={{width:"200px"}}>Section</td>
                    <td className='sites-table-col trh'>Row</td>
                    <td className='sites-table-col tri' style={{width:"50px"}}>Qty</td>
                    <td className='sites-table-col trj' style={{width:"120px"}}>Retail Price</td>
                    <td className='sites-table-col trk' style={{width:"120px"}}>Fee</td>
                    <td className='sites-table-col trl'>Delivery</td>
                    <td className='sites-table-col trm' style={{width:"80px"}}>Taxes</td>
                    <td className='sites-table-col trn' style={{width:"120px"}}>Total Price</td>
                    <td className='sites-table-col tro' style={{width:"100px"}}>Entry Time</td>
                    <td className='sites-table-col trp'>Exit Time</td>
                    <td className='sites-table-col trq' style={{width:"100px"}}>Order Placed</td>
                    <td className='sites-table-col trr'>Attempts</td>
                    <td className='sites-table-col trc'>Email</td>
                    </tr>
                </thead>
                <tbody className='sites-table-body'>
                    { sites.map((row) => {
                        return(
                            <tr className='sites-table-row'>
                                <td className='sites-table-col tra' 
                                onMouseOver={(e) => {
                                            e.target.style.background = '#fb2e2e26'
                                            document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                                c.style.background = '#fb2e2e26'
                                            })
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.background = 'transparent'
                                    document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                        c.style.background = 'transparent'
                                    })
                                }}
                                >{row.sitename}</td>
                                <td className='sites-table-col trb' 
                                onMouseOver={(e) => {
                                            e.target.style.background = '#fb2e2e26'
                                            document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                                c.style.background = '#fb2e2e26'
                                            })
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.background = 'transparent'
                                    document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                        c.style.background = 'transparent'
                                    })
                                }}>{row.orderDate}</td>
                                <td className='sites-table-col traa' 
                                onMouseOver={(e) => {
                                            e.target.style.background = '#fb2e2e26'
                                            document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                                c.style.background = '#fb2e2e26'
                                            })
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.background = 'transparent'
                                    document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                        c.style.background = 'transparent'
                                    })
                                }}
                                >{row.method}</td>
                                <td className='sites-table-col trd' 
                                onMouseOver={(e) => {
                                            e.target.style.background = '#fb2e2e26'
                                            document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                                c.style.background = '#fb2e2e26'
                                            })
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.background = 'transparent'
                                    document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                        c.style.background = 'transparent'
                                    })
                                }}>{row.event}</td>
                                <td className='sites-table-col tre' 
                                onMouseOver={(e) => {
                                            e.target.style.background = '#fb2e2e26'
                                            document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                                c.style.background = '#fb2e2e26'
                                            })
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.background = 'transparent'
                                    document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                        c.style.background = 'transparent'
                                    })
                                }}>{row.date}</td>
                                <td className='sites-table-col trf' 
                                onMouseOver={(e) => {
                                            e.target.style.background = '#fb2e2e26'
                                            document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                                c.style.background = '#fb2e2e26'
                                            })
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.background = 'transparent'
                                    document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                        c.style.background = 'transparent'
                                    })
                                }}>{row.venue.replaceAll(', United States of America', '')}</td>
                                <td className='sites-table-col trg' 
                                onMouseOver={(e) => {
                                            e.target.style.background = '#fb2e2e26'
                                            document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                                c.style.background = '#fb2e2e26'
                                            })
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.background = 'transparent'
                                    document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                        c.style.background = 'transparent'
                                    })
                                }}>{row.section}</td>
                                <td className='sites-table-col trh' 
                                onMouseOver={(e) => {
                                            e.target.style.background = '#fb2e2e26'
                                            document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                                c.style.background = '#fb2e2e26'
                                            })
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.background = 'transparent'
                                    document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                        c.style.background = 'transparent'
                                    })
                                }}>{row.row}</td>
                                <td className='sites-table-col tri' 
                                onMouseOver={(e) => {
                                            e.target.style.background = '#fb2e2e26'
                                            document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                                c.style.background = '#fb2e2e26'
                                            })
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.background = 'transparent'
                                    document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                        c.style.background = 'transparent'
                                    })
                                }}>{row.qty}</td>
                                <td className='sites-table-col trj' 
                                onMouseOver={(e) => {
                                            e.target.style.background = '#fb2e2e26'
                                            document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                                c.style.background = '#fb2e2e26'
                                            })
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.background = 'transparent'
                                    document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                        c.style.background = 'transparent'
                                    })
                                }}>${row.price.replaceAll(' ', '').replaceAll('USD', '').replaceAll('$', '').replaceAll(',', ' ')}</td>
                                <td className='sites-table-col trk' 
                                onMouseOver={(e) => {
                                            e.target.style.background = '#fb2e2e26'
                                            document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                                c.style.background = '#fb2e2e26'
                                            })
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.background = 'transparent'
                                    document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                        c.style.background = 'transparent'
                                    })
                                }}>{row.fee.replaceAll('USD', '')}</td>
                                <td className='sites-table-col trl' 
                                onMouseOver={(e) => {
                                            e.target.style.background = '#fb2e2e26'
                                            document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                                c.style.background = '#fb2e2e26'
                                            })
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.background = 'transparent'
                                    document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                        c.style.background = 'transparent'
                                    })
                                }}>${row.delivery.replaceAll(' ', '').replaceAll('USD', '').replaceAll('$', '').replaceAll(',', ' ')}</td>
                                <td className='sites-table-col trm' 
                                onMouseOver={(e) => {
                                            e.target.style.background = '#fb2e2e26'
                                            document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                                c.style.background = '#fb2e2e26'
                                            })
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.background = 'transparent'
                                    document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                        c.style.background = 'transparent'
                                    })
                                }}>${row.taxes.replaceAll(' ', '').replaceAll('USD', '').replaceAll('$', '').replaceAll(',', ' ')}</td>
                                <td className='sites-table-col trn' 
                                onMouseOver={(e) => {
                                            e.target.style.background = '#fb2e2e26'
                                            document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                                c.style.background = '#fb2e2e26'
                                            })
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.background = 'transparent'
                                    document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                        c.style.background = 'transparent'
                                    })
                                }}>${row.totalPrice.replaceAll(' ', '').replaceAll('USD', '').replaceAll('$', '').replaceAll(',', ' ')}</td>
                                <td className='sites-table-col tro' 
                                onMouseOver={(e) => {
                                            e.target.style.background = '#fb2e2e26'
                                            document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                                c.style.background = '#fb2e2e26'
                                            })
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.background = 'transparent'
                                    document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                        c.style.background = 'transparent'
                                    })
                                }}>{row.in}</td>
                                <td className='sites-table-col trp' 
                                onMouseOver={(e) => {
                                            e.target.style.background = '#fb2e2e26'
                                            document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                                c.style.background = '#fb2e2e26'
                                            })
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.background = 'transparent'
                                    document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                        c.style.background = 'transparent'
                                    })
                                }}>{row.out}</td>
                                <td className='sites-table-col trq' 
                                onMouseOver={(e) => {
                                            e.target.style.background = '#fb2e2e26'
                                            document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                                c.style.background = '#fb2e2e26'
                                            })
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.background = 'transparent'
                                    document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                        c.style.background = 'transparent'
                                    })
                                }}>{(row.orderPlaced) ? 'Yes' : 'No'}</td>
                                <td className='sites-table-col trr' 
                                onMouseOver={(e) => {
                                            e.target.style.background = '#fb2e2e26'
                                            document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                                c.style.background = '#fb2e2e26'
                                            })
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.background = 'transparent'
                                    document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                        c.style.background = 'transparent'
                                    })
                                }}>{row.placedCount}</td>
                                <td className='sites-table-col trc' 
                                onMouseOver={(e) => {
                                            e.target.style.background = '#fb2e2e26'
                                            document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                                c.style.background = '#fb2e2e26'
                                            })
                                }}
                                onMouseOut={(e) => {
                                    e.target.style.background = 'transparent'
                                    document.querySelectorAll('.' + e.target.classList[1]).forEach(c => {
                                        c.style.background = 'transparent'
                                    })
                                }}>{row.email}</td>
                            </tr>
                        )
                    }) }
                </tbody>
            </table>
        )
    }
    
}