import {CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis, Bar, BarChart} from "recharts";
import React, {PureComponent, useEffect, useState} from "react";
import M from "materialize-css";

class CustomizedLabel extends PureComponent {
    render() {
        const { x, y, stroke, value } = this.props;

        return (
            <text x={x} y={y} dy={-8} fill={stroke} fontSize={12} fontWeight={600} textAnchor="middle">
                {value}
            </text>
        );
    }
}

let yearRange = '';
let monthRange = '';
let months = '';
let d = new Date();
d.setDate(1);
for (let i=0; i<=11; i++) {
    let zero = '0'
    if(String((d.getMonth() + 1)).length > 1) {
        zero = ''
    }
    if(i<=1) {
        months += zero + ((d.getMonth() + 1)  + '-' + d.getFullYear()) + '|';
    }
    if(i<=2) {
        monthRange += zero + ((d.getMonth() + 1)  + '-' + d.getFullYear()) + '|';
    }
    yearRange += zero + ((d.getMonth() + 1)  + '-' + d.getFullYear()) + '|';
    d.setMonth(d.getMonth() - 1);
}
months = months.substr(0,months.length - 1)
monthRange = monthRange.substr(0,monthRange.length - 1)
yearRange = yearRange.substr(0,yearRange.length - 1)

// export const Inspector = (data) => {
//     let day = data.value.allDay
//     if(data.value !== '') {
//         console.log(data.value)
//
//         return (
//             <ul className="collapsible">
//
//                     { day.map((user) => {
//                         let userRoutes = JSON.parse(user.visit_urls)
//                         return (
//                             <li>
//                             <div className="collapsible-header"><i className="material-icons">person</i>Пользователь ({ user.ip_address })</div>
//                             <div className="collapsible-body">
//                                 { userRoutes.map(route => {
//                                     return (
//                                         <div className="userpath">
//                                             <span className="pathdate">{ route.time }</span>
//                                             <span className="frompath">{ route.from }</span>
//                                             <span className="topath">{ route.url }</span>
//                                         </div>
//                                     )
//                                 }) }
//                             </div>
//                             </li>
//                         )
//                     }) }
//
//             </ul>
//         )
//     } else {
//         return false
//     }
// }

export const VisitorsChart = (props) => {
    // const [dayData, setDayData] = useState('')
    const [users, setUsers] = useState([])

    useEffect(() => {
        setUsers(props.visitors)
    }, [props.visitors])

    // useEffect(() => {
    //     M.AutoInit(undefined)
    // }, [dayData])

    if(users.length > 0) {
        return (
            <div className="charts">
                <div className="ranges">
                    <div className={(props.active === 3) ? 'period active' : 'period' } onClick={ () => { props.setActive(3); props.refresh(yearRange); } } >За год</div>
                    <div className={(props.active === 2) ? 'period active' : 'period'} onClick={ () => { props.setActive(2); props.refresh(monthRange); } }>3 мес.</div>
                    <div className={(props.active === 1) ? 'period active' : 'period'} onClick={ () => { props.setActive(1); props.refresh(months); } }>За месяц</div>
                </div>
                <div className="chart-wrapper">
                    <div className="chart-title">Статистика посещений</div>
            <LineChart
                width={750}
                height={300}
                data={ users }
                margin={{
                    top: 5,
                    right: 30,
                    left: 0,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="Visits" stroke="#72a5ff" strokeWidth={2}  label={<CustomizedLabel />} />
                <Line type="monotone" dataKey="StubHubClicks" stroke="#3f1d75" strokeWidth={2} label={<CustomizedLabel />} />
            </LineChart>
                </div>
                <div className="chart-wrapper">
                    <div className="chart-title">Посетители из поиска</div>
                <BarChart
                    width={750}
                    height={300}
                    data={ users }
                    margin={{
                        top: 5,
                        right: 30,
                        left: 0,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Google" fill="#4c8aff" />
                    <Bar dataKey="Bing" fill="#268474" />
                    <Bar dataKey="Yahoo" fill="#6001d2" />
                    <Bar dataKey="Duckduck" fill="#1c1c1c" />
                </BarChart>
            </div>
                {/*<div className="chart-wrapper inspector">*/}
                {/*    <Inspector value={ dayData } />*/}
                {/*</div>*/}
            </div>

        )
    } else {
        return (
            <div className="loader">Loading...</div>
        )
    }

}