import React from 'react'
import {Routes, Route} from 'react-router-dom'
import {MonitorPage} from './pages/MonitorPage'
import {SettingsPage} from './pages/SettingsPage'
import AuthPage from './pages/AuthPage'
import {SiteDetailsPage} from './pages/SiteDetailsPage'
import {StatisticsPage} from './pages/StatisticsPage'
import {CheckoutsPage} from './pages/CheckoutsPage'
import {PerformersPage} from './pages/PerformersPage'
import {StbLoader} from './pages/StbLoader'


export const useRoutes = isAuthenticated => {
  if (isAuthenticated) {
    return(
      <Routes>
      <Route path='/monitor' element={<MonitorPage/>} />
      <Route path='/settings' element={<SettingsPage/>} />
      <Route path='/analytics' element={<StatisticsPage/>} />
      <Route path='/checkouts' element={<CheckoutsPage/>} />
      <Route path='/performers' element={<PerformersPage/>} />
      <Route
    path="*"
    element={<MonitorPage/>} />
      </Routes>
    )
  }

  return(
    <Routes>
    <Route path='/' element={<AuthPage/>} />
    <Route
  path="*"
  element={<AuthPage/>} />
    </Routes>
  )
}
