import React, { useEffect, useState } from 'react'


function searchPerformersTNW(keyword, callback) {

  let headers = {};
  headers['Content-Type'] = "application/json"
  let toChart = [];
  fetch("/get/search_performers_tnw", {
      headers: headers,
      method: 'POST',
      body: JSON.stringify({keyword: keyword})
  }).then(r => r.json())
  .then(r => {
      if(r.length) {
          callback(r)
        } else {
          callback([])
        }
  })
  
}

function searchPerformersSTB(keyword, callback) {

    let headers = {};
    headers['Content-Type'] = "application/json"
    let toChart = [];
    fetch("/get/search_performers_stb", {
        headers: headers,
        method: 'POST',
        body: JSON.stringify({keyword: keyword})
    }).then(r => r.json())
    .then(r => {
        if(r.length) {
            callback(r)
          } else {
            callback([])
          }
    })
    
  }

const ColumnTNW = (props) => {

    const [performers, setPerformers] = useState([])
    const [loading, setLoading] =  useState(false)

    useEffect(() => {
        setPerformers([])
        if(props.keyword.length > 2) {
            setLoading(true)
            searchPerformersTNW(props.keyword, (res) => {
                setLoading(false)
                setPerformers(res)
            })
        }

    }, [props.keyword])

    if(performers.length > 0 && !loading) {
        return(
            <div className='performers-list'>
                {
                    performers.map(p => {
                        return(
                            <label className='prow-tnw' for={p.id}>
                            <input id={p.id} 
                            name='etalon' 
                            type='radio' 
                            value={JSON.stringify(p)} 
                            onChange={() => {
                                let value = document.querySelector('.prow-tnw>input:checked').value
                                if(value) {
                                    props.setCheckedTNW(JSON.parse(value))
                                    console.log(value)
                                }
                            }}
                            />
                            {p.text.name}
                        </label>
                        )
                    })
                }
            </div>     
    )
    } else if(loading) {
    return(
            <div className='performers-list'>
                <div id='merger-loading'></div>
            </div>     
    )
    } else {
        return(
            <div className='performers-list'></div> 
        )
    }

 
}

const ColumnSTB = (props) => {

    const [performers, setPerformers] = useState([])
    const [loading, setLoading] =  useState(false)

    useEffect(() => {
        setPerformers([])
        if(props.keyword.length > 2) {
            setLoading(true)
            searchPerformersSTB(props.keyword, (res) => {
                setLoading(false)
                setPerformers(res)
            })
        }

    }, [props.keyword, props.uIndex])

    if(performers.length > 0 && !loading) {
    return(
                    <div className='performers-list'>
                        {
                            performers.map(p => {
                                return(
                                    <label className={(p.categories) ? 'prow-stb merged' : 'prow-stb'} for={p.stub_id} title={p.stub_category}>
                                        <input id={p.stub_id} 
                                        type='checkbox' 
                                        value={JSON.stringify(p)}
                                        onChange={() => {
                                            let checkboxes = document.querySelectorAll('.prow-stb>input:checked')
                                            let values = []
                                            checkboxes.forEach(e => {
                                                values.push(JSON.parse(e.value))
                                            })
                                            props.setCheckedSTB(values)
                                            console.log(values)
                                        }}
                                        />
                                        {p.stub_name}
                                    </label>
                                )
                            })
                        }
                    </div>     
    )
    } else if(loading) {
        return(
                <div className='performers-list'>
                    <div id='merger-loading'></div>
                </div>     
        )
        } else {
            return(
                <div className='performers-list'></div> 
            )
        }
    
 
}

async function startMerge(tnw, stb, callback) {
    let merged = []
    if(tnw && stb) {
        stb.forEach(s => {
            let new_stb = JSON.parse(JSON.stringify(s))
            new_stb.tnw_name = tnw.text.name
            new_stb.tnw_id = tnw.id
            new_stb.categories = tnw.defaultCategory
            merged.push(new_stb)
        })
        console.log(merged)
        let headers = {};
        headers['Content-Type'] = "application/json"
        let toChart = [];
        fetch("/get/merge_performers_menually", {
            headers: headers,
            method: 'POST',
            body: JSON.stringify(merged)
        }).then(r => r.json())
        .then(r => {
            if(r.status !== 'error') {
                callback()
            } else {
                alert(r.message)
            }
        }).catch(e => alert(e))
    }
}

export const PerformersPage = () => {

        const [keywordTNW, setKeywordTNW] = useState("")
        const [keywordSTB, setKeywordSTB] = useState("")
        const [checkedTNW, setCheckedTNW] = useState(null)
        const [checkedSTB, setCheckedSTB] = useState([])
        const [uIndex, updateIndex] = useState(0)
        const [tDisplay, settDisplay] = useState(0)

        return(
        <div className="workspace">
            <div className="tab-title">Перформеры</div>
  
            <div className="merge-container">

                <div className='performers-columns'>
                <div className='performer-column'>
                        <input type='text' 
                        placeholder='Поиск перформера TNW...'
                        onKeyUp = {e => {
                            setKeywordTNW(e.target.value)
                            e.target.setAttribute('value', e.target.value)
                        }
                        }
                       
                        />
                    <ColumnTNW keyword={keywordTNW} setCheckedTNW={setCheckedTNW} />
                </div>
                <div className='performer-column'>
                        <input class='stb-search-input' 
                        type='text' 
                        placeholder='Поиск перформера STB...'
                        onKeyUp={(e) => {
                            settDisplay(0)
                            setKeywordSTB(e.target.value)
                            e.target.setAttribute('value', e.target.value)
                        }}
                        onClick={() => {
                            settDisplay(1)
                        }}
                       
                        />
                    <div className='merging-tooltip'
                    onClick={() => {
                        settDisplay(0)
                        document.querySelector('.stb-search-input').setAttribute('value', keywordTNW)
                        setKeywordSTB(keywordTNW)
                    }}
                    style={{transform:`scale(${tDisplay})`}}
                    >{keywordTNW}</div>
                    <ColumnSTB keyword={keywordSTB} setCheckedSTB={setCheckedSTB} uIndex={uIndex} />
                </div>
                </div>
                <div className='start-merge'
                onClick={() => {
                    startMerge(checkedTNW, checkedSTB, () => {
                        updateIndex(uIndex + 1)
                        document.querySelectorAll('.prow-stb>input:checked').forEach(e => e.click())
                    })
                }}
                >Склеить</div>
            </div>
  
        </div>
        )

    }
