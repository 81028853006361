import React, { useEffect, useState } from 'react'

import { Checkouts } from '../components/analytics/Checkouts'



export const CheckoutsPage = () => {

      const [sites, setSites] = useState('all')
      const [period, setPeriod] = useState('today')
      const [periodMonth, setPeriodMonth] = useState((new Date().getMonth()+1))
      const [year, setYear] = useState((new Date().getFullYear()))
        
        return(
          <div className="workspace">
            <div className="tab-title">Чекауты</div>
  
            <div className="stat-container">

            <div className='top-sites-block'>
              <Checkouts />
            </div>

            <div className='chart-block'>
                {/* <ChartCard sites={sites} month={periodMonth} year={year}/> */}
            </div>

            <div className='top-sites-block'>
                {/* <TopSitesCard period={period} year={year} /> */}
            </div>

            </div>
  
          </div>
        )
        

    }
