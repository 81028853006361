import React, {useEffect, useState} from "react";
import { Link } from 'react-router-dom'
import M from "materialize-css"



export const Lamp = (status) => {
  if(status.value === 'error') {
    return (
      <div className="lamp red flicker"/>
    )
  } else if(status.value === 'no dates') {
    return (
      <div className="lamp orange"/>
    )
  } else if(status.value === 'ok') {
    return (
      <div className="lamp green"/>
    )
  } else if(status.value === 'loading') {
    return (
      <div className="lamp white"/>
    )
  }
}

export const SiteCard = (props) => {
  const site = props.site
  const stat = Number(site.status)
  var host = site?.host
  var domain = site?.domain

  if(!host) {
    host = 'N/A'
  }
  if(!domain) {
    domain = 'N/A'
  }

  const [lamp, setLamp] = useState("loading")


  useEffect(() => {

    if(stat === 200) {
      setLamp('ok')
    } else {
      setLamp('error')
    }

    function updateSitesInfo() {
      setLamp('loading')
      let headers = {};
      headers['Content-Type'] = "application/json"
      fetch("/get/site/id", { headers: headers, method: 'POST', body: JSON.stringify({id: site._id})})
        .then(res => res.json())
        .then(
          (result) => {
            let status = Number(result.status)
            if(status === 200) {
              setLamp('ok')
            } else {
              setLamp('error')
            }
  
          },
          (error) => {
              console.log(error)

            setLamp('error')
          }
        )
    }
      updateSitesInfo()
      M.AutoInit(undefined);

  },[])


  return (
    <a className="site-card" href={site.data.site_url} target="_blank" rel="noreferrer noopener">
      <Lamp value={lamp} />
      <div className='panel'>
      <div className="site-name">{site.id}</div>
      <div className='btn-panel' >
         <div className='host'>Хост: {host}</div>
         <div className='host'>Домен: {domain}</div>
      </div>
      </div>
    </a>
  )

}
