import React, {FC, useContext, useState} from 'react';
import {Context} from "../index";
import {observer} from "mobx-react-lite";


const AuthPage: FC = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const {store} = useContext(Context);

  return(
    <div className="login-wrap">

        <div className="card main-color">
          <div className="card-content white-text">
            <span className="card-title"><span>IWEBI</span> Control Panel</span>
              <div>
              <div className="input-field">
              <input
                id="login"
                name="login"
                type="text"
                onChange={e => setEmail(e.target.value)}
                />
              <label htmlFor="login">Username</label>
            </div>
            <div className="input-field">
            <input
              id="password"
              name="password"
              type="password"
              onChange={e => setPassword(e.target.value)}
              />
            <label htmlFor="password">Password</label>
          </div>

              </div>
          </div>

          <div className="card-action">
              <button className="btn main-b"
                      onClick={() => store.login(email, password)}
                >Sign In</button>
          </div>
        </div>
    </div>
  )
}

export default observer(AuthPage);