import React, {useEffect, useState} from 'react'
import {useMessage} from '../hooks/message.hook'
import { SiteCard } from '../components/SiteCard'

export const Sites = () => {
  const [sites, setSitesData] = useState([])
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const message = useMessage()
  const [update, setUpdate] = useState(1)


  useEffect(() => {

    function renderSites() {
      if(update === 1) {
        message('Загрузка сайтов...')
      } else {
        message('Обновление...')
      }
    fetch("/get/sites", { method: 'POST'})
      .then(res => res.json())
      .then(
        (result) => {
          if(update === 1) {
          message('Сайты загружены')
          } else {

          }
          let sites = [];
          result.map((data, id)=> {
              if(data?.id) {
              data.status = JSON.parse(data.status)
       
              let status = Number(data.status);

              if(status === 200) {
                data.sort_index = 1;
              } else {
                data.sort_index = 0;
              }

              sites.push(data);
            }
             
          })
          sites.sort((a, b) => {
            return a.sort_index - b.sort_index
          })
          setIsLoaded(true)
          setSitesData(sites)

        }).catch(
        (error) => {
          setError(error)
        }
      )
    }
    renderSites()

  }, [])




if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div className="site-cards"></div>
  } else {
    return (<div className="site-cards">
        {sites.map((data, id)=> {
        return <SiteCard key={data._id} site={data} />
        })}
            </div>
    )
  }

}
