import React from 'react'
import { NavLink } from 'react-router-dom'

export const Navbar = () => {

    return (
      <header id="header">
             <div className="logotip"><span>IWEBI</span> <span>Control Panel</span></div>
              <div className="main-menu">
                  <NavLink to="/" className="menu-item">
                   <div className="menu-label">Мониторинг</div>
                  </NavLink>
                  <NavLink to="/analytics" className="menu-item">
                     <div className="menu-label">Аналитика</div>
                  </NavLink>
                  <NavLink to="/checkouts" className="menu-item">
                     <div className="menu-label">Чекауты</div>
                  </NavLink>
                  <NavLink to="/settings" className="menu-item">
                     <div className="menu-label">Настройки</div>
                  </NavLink>
                  <NavLink to="/performers" className="menu-item">
                     <div className="menu-label">Перформеры</div>
                  </NavLink>
              </div>
         </header>

    )

}
