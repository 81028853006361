import React from 'react'
import { Sites } from '../components/Sites'
import M from 'materialize-css'


export const MonitorPage = () => {
  M.AutoInit();
  return(
    <div className="workspace">
      <div className="tab-title">Мониторинг</div>
      { <Sites /> }
    </div>
  )
}
