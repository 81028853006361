import React, {useEffect, useState} from 'react'



const SiteRows = (props) => {
    const [sites, setSitesData] = useState([])
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
  
    
    useEffect(() => {
  
      function renderSites() {
        setIsLoaded(false)
      fetch("/get/sites", { method: 'POST'})
        .then(res => res.json())
        .then(
          (result) => {
            let sites = [];
            result.map((data, id)=> {
              data.status = JSON.parse(data.status)
       
              let status = Number(data.status);

              if(status === 200) {
                data.sort_index = 1;
                data.lamp = 'green'
              } else {
                data.sort_index = 0;
                data.lamp = 'red'
              }
              if(!data.host) {
                 data.host = ''
              }
              if(!data.domain) {
                data.domain = ''
             }
              sites.push(data);
            })
            sites.sort((a, b) => {
              return a.sort_index - b.sort_index
            })
            setIsLoaded(true)
            setSitesData(sites)
          }).catch(
          (error) => {
            setError(error)
          }
        )
      }
      renderSites()
      
    }, [props.deleted])



    if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div className="site-cards"></div>
      } else {
        return (
            sites.map((site, id) => <SiteRow  site={site} delname={props.delname} delid={props.delid} setpopup={props.setpopup} />) 
        )
      }

}

function updateSite(id, sitename, host, domain, url) {
  let headers = {};
  headers['Content-Type'] = "application/json"
  fetch("/get/updatesite", { 
    headers: headers,
    method: 'POST',
    body: JSON.stringify({
      id: id,
      sitename: sitename,
      host: host,
      domain: domain,
      url: url
    })
  })
  .then(res => res.json())
  .then(
    (result) => {
     
    })
}

function deleteSite(id) {
  let headers = {};
  headers['Content-Type'] = "application/json"
  fetch("/get/deletesite", { 
    headers: headers,
    method: 'POST',
    body: JSON.stringify({
      id: id
    })
  })
  .then(res => res.json())
  .then(
    (result) => {
     
    })
}

function addNewSite(sitename, host, domain, url) {
  let headers = {};
  headers['Content-Type'] = "application/json"
  fetch("/get/addsite", { 
    headers: headers,
    method: 'POST',
    body: JSON.stringify({
      sitename: sitename,
      host: host,
      domain: domain,
      url: url
    })
  })
  .then(res => res.json())
  .then(
    (result) => {
     
    })
}

const SiteRow = (props) => {
  const [mode, setMode] = useState('div')
  const [siteID, setID] = useState(props.site.id)
  const [sitename, setSitename] = useState(props.site.data.name)
  const [host, setHost] = useState(props.site.host)
  const [domain, setDomain] = useState(props.site.domain)
  const [url, setUrl] = useState(props.site.data.site_url)


  useEffect(() => {

  }, [mode])
  if(mode === 'div') {
  return(
    <div className='table-row'>
    <div className={'lamp left ' + props.site.lamp}></div>
    <div className='site-name-col'>{sitename}</div>
    <div className='tickets-col'>{url}</div>
    <div className='tickets-col'>{host}</div>
    <div className='tickets-col'>{domain}</div>
    <div className='button-col'>
        <div className='site-edit' onClick={()=> setMode('input')} >Редактировать</div>
        <div className='site-delete' onClick={()=> {
            props.delname(sitename) 
            props.delid(siteID) 
            props.setpopup(true)
          }}>Удалить</div>
    </div>
  </div>
  )
  } else if(mode === 'input') {
    return(
      <div className='table-row'>
      <div className={'lamp left ' + props.site.lamp}></div>
      <input className='site-name-col' value={sitename} onChange={e => setSitename(e.target.value)} placeholder={'Название сайта'} />
      <input className='tickets-col' value={url} onChange={e => setUrl(e.target.value)} placeholder={'URL'} />
      <input className='tickets-col' value={host} onChange={e => setHost(e.target.value)} placeholder={'Хост'} />
      <input className='tickets-col' value={domain} onChange={e => setDomain(e.target.value)} placeholder={'Домен'} />
      <div className='button-col'>
          <div className='site-edit' onClick={()=> { 
            setMode('div')
            updateSite(siteID, sitename, host, domain, url)
          }} >Сохранить</div>
          <div className='site-delete' onClick={()=> {
              props.delname(sitename) 
              props.delid(siteID) 
              props.setpopup(true)
          }}>Удалить</div>
      </div>
    </div>
    )
  }

}

export const DeletePopup = (props) => {
 useEffect(() => {}, [props.popup])
  if(props.popup) {
    return(
      <div className='popup-w'>
      <div className="delete-popup">
          <div className='question'>Вы уверены что хотите удалить сайт {props.name}?</div>
          <div className='btns'>
              <div className='choice-no' onClick={()=> {
                props.setpopup(false)
               }}>Нет</div>
              <div className='choice'  onClick={()=> {
                deleteSite(props.id)
                props.setpopup(false)
                props.setdeleted(props.deleted + 1)
               }}>Да</div>
          </div> 
      </div>
      </div>
    )
  } else {
    return(
      <div className='popup-w deactive'>
      <div className="delete-popup"></div>
      </div>
    )
  } 
}

export const AddPopup = (props) => {

  const [sitename, setSitename] = useState('')
  const [host, setHost] = useState('')
  const [domain, setDomain] = useState('')
  const [url, setUrl] = useState('')

  useEffect(() => {}, [props.popup])
   if(props.addpopup) {
     return(
       <div className='popup-w'>
       <div className="delete-popup">
          <input className='site-name-col' value={sitename} onChange={e => setSitename(e.target.value)} placeholder={'Название сайта'} />
          <input className='tickets-col' value={url} onChange={e => setUrl(e.target.value)} placeholder={'URL'} />
          <input className='tickets-col' value={host} onChange={e => setHost(e.target.value)} placeholder={'Хост'} />
          <input className='tickets-col' value={domain} onChange={e => setDomain(e.target.value)} placeholder={'Домен'} />
          <div className='btns'>
              <div className='choice-no' onClick={()=> {
                props.setaddpopup(false)
               }}>Отмена</div>
              <div className='choice'  onClick={()=> {
                addNewSite(sitename, host, domain, url)
                props.setaddpopup(false)
                props.setdeleted(props.deleted + 1)
               }}>Добавить</div>
          </div> 
       </div>
       </div>
     )
   } else {
     return(
       <div className='popup-w deactive'>
       <div className="delete-popup"></div>
       </div>
     )
   } 
 }

export const SettingsPage = () => {
  const [popup, setPopup] = useState(false)
  const [name, setName] = useState('')
  const [id, setID] = useState('')
  const [deleted, setDeleted] = useState(0)
  const [addpopup, setAddPopup] = useState(false)

  return(
    <div className="workspace">
      <div className="tab-title sett">Настройки</div>
      <div className='add-new-site' onClick={() => { setAddPopup(true) }}>Добавить сайт +</div>
        <div className='sites-list'>
            <SiteRows delname={setName} delid={setID} setpopup={setPopup} deleted={deleted} />
        </div> 
            <DeletePopup popup={popup} setpopup={setPopup} name={name} id={id} setdeleted={setDeleted} deleted={deleted}/>
            <AddPopup addpopup={addpopup} setaddpopup={setAddPopup} setdeleted={setDeleted} deleted={deleted}/>
    </div>
  )
}
