import React, {useEffect, useState} from 'react'

function uploadToSite(site) {
    let headers = {};
    headers['Content-Type'] = "application/json"
    fetch("/get/sendschedule", { 
        headers: headers,
        method: 'POST',
        body: JSON.stringify({
          site: site
        })
    })
    .then(res => res.text())
    .then(
      (result) => {
        console.log(result)
      }).catch(
      (error) => {
        alert(error)
      }
    )
  }

async function uploadToDB(dates) {
    let datesArrs = []
    if(dates.length > 25) {
        let itara = 0
        let datesBlock = [];
        for await(let date of dates) {
            itara++
            if(itara <= 25) {
                datesBlock.push(date)
            } else {
                datesArrs.push(datesBlock) 
                itara = 1
                datesBlock = []
                datesBlock.push(date)
            }
        }
        
    } else {
        datesArrs = [dates]
    }

    console.log(datesArrs);

    if(datesArrs.length) {
    let headers = {};
    headers['Content-Type'] = "application/json"

    for await(let dts of datesArrs) {
        await fetch("/get/sendschedule-to-db", { 
            headers: headers,
            method: 'POST',
            body: JSON.stringify({
              dates: dts
            })
        })
        .then(res => res.text())
        .then(
          (result) => {
            console.log(result)
          }).catch(
          (error) => {
            alert(error)
          }
        )
    }



    } else {
        alert('Не загружены даты либо не выбран сайт')
    }
}

const SitesList = (props) => {
    const [sites, setSitesData] = useState([])
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
  
    
    useEffect(() => {
  
      function renderSites() {
      
      fetch("/get/sites", { method: 'POST'})
        .then(res => res.json())
        .then(
          (result) => {
            let sites = result.sort(function(a, b){
                var nameA=a.id.toLowerCase(), nameB=b.id.toLowerCase()
                if (nameA < nameB) //сортируем строки по возрастанию
                  return -1
                if (nameA > nameB)
                  return 1
                return 0 // Никакой сортировки
                })
            setIsLoaded(true)
            setSitesData(sites)
          }).catch(
          (error) => {
            setError(error)
          }
        )
      }
      renderSites()
      
    }, [])

    if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div className="site-cards"></div>
      } else {
        return (
            <select className='sites-select' onChange={e => props.setSite(e.target.value) }>
                <option value=''>Select Site...</option>
                 {
            sites.map((site, id) => {
            return (
                <option value={JSON.stringify(site)}>{site.data.name}</option>
            )
            }) 
        }
            </select>
        )
      }

}


const StbResults = (props) => {
    
    if(props.status === false) {
        return (
            <div className='stb-results'></div>
        )
    }
    if(props.status === 'loading') {
        return (
<svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" stroke="#fff">
    <g fill="none" fill-rule="evenodd">
        <g transform="translate(1 1)" stroke-width="2">
            <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
            <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite"/>
            </path>
        </g>
    </g>
</svg>
        )
    }
    if(props.status === true) {
        let datesArr = props.dates.sort((a, b) => {
            return Date.parse(a.eventDateLocal) - Date.parse(b.eventDateLocal)
        })
        return (
            datesArr.map((date) => {
            let data = new Date(Date.parse(date.eventDateLocal))
            let dateString = String(data.getDate())
            if(dateString.length < 2) {
                dateString = '0' + dateString
            }
            let monthString = String(data.getMonth()+1)
            if(monthString.length < 2) {
                monthString = '0' + monthString
            }
            let yearString = data.getFullYear()
            return(
            <div className='stb-results'>
                <div className='stb-time'>{ dateString + '-' + monthString + '-' + yearString }</div>
                <div className='stb-name'>{ date.name }</div> 
            </div>
            )
            }
            )
        )
    }

}


export const StbLoader = () => {

    const [phrase, setPhrase] = useState('')
    const [dates, setDates] = useState([])
    const [status, setStatus] = useState(false)
    const [site, setSite] = useState(null)
   
    useEffect(() => {
        function loadStubhub(phrase) {
            let headers = {};
            headers['Content-Type'] = "application/json"
            
            fetch("/get/get_schedule", { 
            headers: headers,
            method: 'POST',
            body: JSON.stringify({
              phrase: phrase
            })
        }).then(res => res.json()).then(
              async (result) => {
                if(result.items.length > 0) {
                let itemsFiltered = [];
                for await(let date of result.items) {
                    if(date.webURI.indexOf('stubhub.com') > -1) {
                        if(date.venue.country === 'US' || date.venue.country === 'CA') {
                            if(date.name.indexOf('DO NOT USE') < 0) {
                            console.log('ok')
                        let newURL = date.webURI.replace('https://www.stubhub.com/', '')
                        let newDate = JSON.parse(JSON.stringify(date))
                        newDate.webURI = newURL
                        itemsFiltered.push(newDate)
                        }
                        }
                    }
                }
                setDates(itemsFiltered)
                setStatus(true)
               
                } else {
                setStatus(false)
                setDates([])
                
                }
              })
        
        }
        if(phrase.length > 0) {
            loadStubhub(phrase)
        } else {
            setStatus(false)
            setDates([])
    
        }
        
    }, [status])



      return(
        <div className="workspace">
          <div className="tab-title">Новый загрузчик StubHub</div>
            <div className='search-row'>
                <input type='text' placeholder='Введите поисковую фразу...' onChange={e => setPhrase(e.target.value)}/>
                <div className='start-search' onClick={ () => {
                        setStatus('loading')
                } }>Поиск</div>
            </div>
            <div className='results-box' >
                <StbResults status={status} dates={ dates }/>
            </div>
            <div className='sites-box'> 
                <SitesList  setSite={ setSite }/>
                <div className='start-search upload-btn' onClick={ () => {
                       uploadToSite(site)
                } }>Загрузить</div>
                <div className='start-search upload-btn' onClick={ () => {
                       uploadToDB(dates)
                } }>Загрузить даты в базу</div>
            </div>
        </div>
      )


    }