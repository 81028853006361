import React, { useEffect, useState } from 'react'
import { VisitorsCard } from '../components/analytics/VisitorsCard'
import { ChartCard } from '../components/analytics/ChartCard'
import { TopSitesCard } from '../components/analytics/TopSitesCard'
import { StatsTable } from '../components/analytics/StatsTable'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

function getVisitors(period, callback) {

  let headers = {};
  headers['Content-Type'] = "application/json"
  let toChart = [];
  fetch("/get/analytics/count", {
      headers: headers,
      method: 'POST',
      body: JSON.stringify({period: period})
  }).then(r => r.json())
  .then(r => {
      if(r?.result) {
          callback(r.result)
        } else {
          callback([])
        }
  })
  
}
let today = new Date()
let year_c = String(today.getFullYear())
let month_c = String(today.getMonth() + 1)
let day_c = String(today.getDate())
if(month_c.length < 2) {
  month_c = '0' + month_c
}
if(day_c.length < 2) {
  day_c = '0' + day_c
}

export const StatisticsPage = () => {
      const [loading, setLoading] = useState(true)
      const [period, setPeriod] = useState(`${month_c}/${day_c}/${year_c}|${month_c}/${day_c}/${year_c}`)
      const [visitors, setVisitors] = useState([])
      const [all, setAll] = useState(true)
      const [dates, setDates] = useState(true)
      const [checkouts, setCheckouts] = useState(true)

      const [startDate, setStartDate] = useState(new Date())
      const [endDate, setEndDate] = useState(new Date())



        
      useEffect(async () => {
        setLoading(true)
        await getVisitors(period, async (r) => {
          let vis = []
      let counts = {
            all: 0,
            desktop: 0,
            mobile: 0,
            tablet: 0
      }
        let counts_d = {
          all: 0,
          desktop: 0,
          mobile: 0,
          tablet: 0
      }
        let counts_ch = {
        all: 0,
        desktop: 0,
        mobile: 0,
        tablet: 0
        }
        for await(let v of r) {
                counts.all = counts.all + v.visits
                counts.desktop = counts.desktop + (v.visits - v.visits_m - v.visits_t)
                counts.mobile = counts.mobile + v.visits_m
                counts.tablet = counts.tablet + v.visits_t
                counts_ch.all = counts_ch.all + v.checkouts
                counts_ch.desktop = counts_ch.desktop + (v.checkouts - v.checkouts_m - v.checkouts_t)
                counts_ch.mobile = counts_ch.mobile +  v.checkouts_m
                counts_ch.tablet = counts_ch.tablet +  v.checkouts_t
                counts_d.all = counts_d.all + v.dates
                counts_d.desktop = counts_d.desktop + (v.dates - v.dates_m - v.dates_t)
                counts_d.mobile = counts_d.mobile + v.dates_m
                counts_d.tablet = counts_d.tablet + v.dates_t
                let new_v = JSON.parse(JSON.stringify(v))
                new_v.conversion = Math.round(v.checkouts/v.visits*1000)/10
                vis.push(new_v)
        }
        setVisitors(vis)
        setAll(counts)
        setDates(counts_d)
        setCheckouts(counts_ch)
            
         

          
            setLoading(false)
        })
      }, [period])

      if(!loading) {
        return(
          <div className="workspace">
            <div className="tab-title">Аналитика</div>
  
            <div className="stat-container">

            <div className="counts-row">
            <VisitorsCard loading={loading}  title={'Всего посетителей'} visitors={all} />
            <VisitorsCard loading={loading}  title={'Даты'} visitors={dates} />
            <VisitorsCard loading={loading} title={'Чекауты'} visitors={checkouts} />





            </div>

            <div className="counts-row">
            <div className='calendar-wrapper'>
            <div className='counter-title'>Start Date</div>
            <Calendar onChange={(d) => {
              setStartDate(d)
              setEndDate(d)
            }} value={startDate} />
            </div>
            <div className='calendar-wrapper'>
            <div className='counter-title'>End Date</div>
            <Calendar onChange={(d) => {
              setEndDate(d)
            }} value={endDate} />
                        <div className='set-dates' 
            onClick={() => {
              console.log(startDate + "|" + endDate)
            }}
            >Apply</div>
            </div>
            </div>

            <div className='top-sites-block'>
              <StatsTable visitors={visitors} />
            </div>

            <div className='chart-block'>
                {/* <ChartCard sites={sites} month={periodMonth} year={year}/> */}
            </div>

            <div className='top-sites-block'>
                {/* <TopSitesCard period={period} year={year} /> */}
            </div>

            </div>
  
          </div>
        )
      } else {
        return(
          <div className="workspace">
            <div className="tab-title">Аналитика</div>
  
            <div className="stat-container">

            <div className="counts-row">
            <VisitorsCard loading={loading}  title={'Всего посетителей'} visitors={all} />
            <VisitorsCard loading={loading}  title={'Даты'} visitors={dates} />
            <VisitorsCard loading={loading} title={'Чекауты'} visitors={checkouts} />
            </div>

            <div className='top-sites-block'>

            </div>

            <div className='chart-block'>
                {/* <ChartCard sites={sites} month={periodMonth} year={year}/> */}
            </div>

            <div className='top-sites-block'>
                {/* <TopSitesCard period={period} year={year} /> */}
            </div>

            </div>
  
          </div>
        )
      }

    }
