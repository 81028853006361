import React, { useEffect, useState } from 'react'


export const VisitorsCard = (props) => {

    var title = props.title
    let visitors = props.visitors
    let loading = props.loading
    


    if(!loading) {
        return(
            <div className='counter-wrapper'>
                <div className='counter-title'>{title}</div>
                <div className='count-circle'>{visitors.all}</div>
                <div className='devices'>
                    <div className='dev-char'>
                        <div className='dev-count'>{visitors.desktop}</div>
                        <div className='dev-percent'>Desktop</div> 
                    </div>
                    <div className='dev-char'>
                        <div className='dev-count'>{visitors.tablet}</div>
                        <div className='dev-percent'>Tablet</div> 
                    </div>
                    <div className='dev-char'>
                        <div className='dev-count'>{visitors.mobile}</div>
                        <div className='dev-percent'>Mobile</div> 
                    </div>
                </div>
            </div>
        )
    } else {
        return(
            <div className='counter-wrapper blurred'>
                <div className='counter-title'>{title}</div>
                <div className='count-circle'>...</div>
                <svg xmlns="http://www.w3.org/2000/svg" width="140" height="140" viewBox="0 0 38 38" stroke="#2e84fb"><g fill="none" fill-rule="evenodd"><g transform="translate(1 1)" stroke-width="1.5"><circle stroke-opacity=".5" cx="18" cy="18" r="18"></circle><path d="M36 18c0-9.94-8.06-18-18-18"><animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite"></animateTransform></path></g></g></svg>
            </div>
        )
    }
    
}

